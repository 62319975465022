<script>
/* eslint-disable */
</script>
<template>
  <div>
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-xl2
      variant="outline-primary"
      >
      Importa Contatti
    </b-button>
    <b-modal
      ref="modalTemplateMapped"
      id="modal-load-mapping"
      title="Carica Mapping"
      hide-footer
      centered
      no-close-on-backdrop
      ok-title="Conferma"
      @show="loadMapping"

    >
      <div v-if="mappingTemplateSaved.length === 0">
        <b-card-text
          class="m-1">
          <h5>Nessun Template Salvato</h5>
        </b-card-text>
      </div>
      <div
        v-else
        v-for="(template, index) in mappingTemplateSaved"
        :key="index"
      >
        <b-alert
          variant="primary"
          show
          class="mx-1"
        >
          <b-row>
            <b-col cols="9">
              <b-card-text
                class="m-1">
                <h5>{{ template.module }}              
                  <feather-icon
                    v-if="typeModule === 'Contacts'"
                    icon="UsersIcon"
                    class=""
                  />
                </h5>
                Colonne mappate: {{ template.mapping.filter(elementMapping => elementMapping.vtigerField.field !== '').length }}
              </b-card-text>
            </b-col>
            <b-col class="d-flex align-items-center">
              <b-card-text
                class="m-1 d-flex align-items-center">
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-2"
                    @click="uploadTemplateMapped(template)"
                    style="cursor: pointer"
                  />
                  <div type="button" @click="deleteTemplateMapped(template, index)">
                    <feather-icon
                      icon="TrashIcon"
                      class=""
                      style="cursor: pointer"
                    />
                  </div>
              </b-card-text>
            </b-col>
          </b-row>
        </b-alert>
      </div>
    </b-modal>
    <b-modal
      id="modal-xl2"
      centered
      hide-footer
      no-close-on-backdrop
      size="xl"
      title="Importa Contatti"
      @show="getModules"
      @close="resetModal"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Importa"
        back-button-text="Precedente"
        next-button-text="Successivo"
        class=""
        @on-complete="formSubmitted"
        @on-change="getInfoModuleVtiger"
      >

        <!-- accoint details tab -->
        <tab-content
          title="Carica CSV"
          :before-change="validationLoadCSV"
        >
          <b-row>
            <b-col
              cols="12"
              class=""
            >
              <validation-observer
                ref="loadCSVform"
                tag="form"
              >
                <validation-provider
                  #default="{ errors }"
                  name="modulo"
                  rules="required"
                >
                  <b-form-group
                    :label="'Modulo Vtiger'"
                    label-for="typeModule"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <v-select
                      :id="`${'typeModule'}`"
                      v-model="typeModule"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typeModuleOptions"
                      :clearable="false"
                      class=""
                      style="max-width: 200px"
                    />
                    <small class="text-danger mt-25">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                    name="CSV"
                    rules="required"
                  >
                  <b-form-file
                    v-model="file"
                    @change="onLoadFile"
                    multiple
                    size="lg"
                    accept=".csv"
                    class="drop-zone p-0"
                  >
                    <template slot="drop-placeholder">
                      <b-row class="w-100 h-100 align-content-center position-absolute">
                        <b-col class="d-flex flex-column align-items-center">
                          <b-img
                            :src="require('@/@core/assets/iconpro/cloud-arrow-up.svg')"
                            width="70px"
                            height="70px"
                          />
                          <h4>Trascina i Tuoi file qui</h4>
                          <div>
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                    <template slot="placeholder">
                      <b-row class="w-100 h-100 align-content-center position-absolute">
                        <b-col class="d-flex flex-column align-items-center">
                          <b-img
                            :src="require('@/@core/assets/iconpro/cloud-arrow-up.svg')"
                            width="70px"
                            height="70px"
                          />
                          <h4>Trascina i Tuoi file qui...</h4>
                          <h4>Oppure clicca qui</h4>
                        </b-col>
                      </b-row>
                    </template>
                  </b-form-file>
                </validation-provider>
              </validation-observer>
            </b-col>
          </b-row>
        </tab-content>

        <!-- personal details tab -->
        <tab-content
          title="Mapping Campi"
          :before-change="validationMapping"
        >
          <validation-observer
            ref="mappingRules"
            tag="form"
          >
            <b-overlay
              id="overlay-background"
              :show="apiLoading"
              :variant="'light'"
              :opacity="0.85"
              :blur="'2px'"
              rounded="sm"
            >
              <b-row>
                <b-alert show class="p-2 w-100"><p>Sono campi obbligatori: <span v-for="(field, index) in mandatoryFields" :key="index"> <b-badge variant="primary" class="mr-50">{{ field.label }}</b-badge></span></p></b-alert>
                  <b-col
                    cols="12"
                    class="mb-2 d-flex justify-content-end"
                  >
                    <b-button variant="primary" v-b-modal.modal-load-mapping>Carica Template Salvati</b-button>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <h5 class="mb-0">
                      Scegli i campi che vuoi mappare
                    </h5>
                  </b-col>                  
                </b-row>
                <b-row>
                  <b-col cols="3"><h4><strong>Column Name</strong></h4></b-col>
                  <b-col cols="2"><h4><strong>Value</strong></h4></b-col>
                  <b-col cols="3"><h4><strong>CRM Field</strong></h4></b-col>
                  <b-col cols="1"><h4><strong>P.K.</strong></h4></b-col>
                  <b-col cols="3"><h4><strong>Default Value</strong></h4></b-col>
                  <b-col cols="12" class="border mb-1"></b-col>
                </b-row>
                <b-row
                  v-for="(obj, index) in csvMappingFields"
                  :key="index"
                  class="my-1"
                >
                  
                  <b-col cols="3">
                    <strong>
                      {{ obj.csvField }}
                    </strong>             
                  </b-col>
                  <b-col cols="2">
                    <div>
                      {{ obj.csvFieldValue }}
                    </div>
                  </b-col>
                  <b-col cols="3">
                    <div>
                      <v-select
                        :id="`${'crm_field' + index}`"
                        v-model="obj.vtigerField"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="vtigerFields"
                        label="label"
                        :clearable="false"
                        @input="addTypeForDefaultValue(obj)"
                        :reduce="el => ({ field: el.name, label: el.label})"
                      />
                    </div>
                  </b-col>
                  <b-col cols="1">
                      <b-form-checkbox
                        v-model="obj.isPrimaryKey"
                        :value="true"
                        :unchecked-value="false"
                        @change="setPrimaryKeyField(obj)"
                      />
                  </b-col>
                  <b-col cols="3">
                    <div v-if="obj.type.name === 'picklist'">
                      <v-select
                        :id="`${'crm_field_defaultvalue' + index}`"
                        v-model="obj.vtigerDefaultValue"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="obj.type.picklistValues"
                        label="label"
                        :clearable="false"
                        :reduce="el => el.value"
                      />
                    </div>
                    <div v-else-if="obj.vtigerField.field.length > 0">
                      <b-form-input v-model="obj.vtigerDefaultValue" />
                    </div>
                  </b-col>
                  <b-col cols="12" class="border my-50"></b-col>
                </b-row>
            </b-overlay>
          </validation-observer>
        </tab-content>

        <!-- address  -->
        <tab-content
          title="Conferma"
        >
          <validation-observer
            ref="submitForm"
            tag="form"
          >
            <b-row>
                  <b-col cols="3"><h4><strong>Column Name</strong></h4></b-col>
                  <b-col cols="2"><h4><strong>Value</strong></h4></b-col>
                  <b-col cols="3"><h4><strong>CRM Field</strong></h4></b-col>
                  <b-col cols="1"><h4><strong>P.K.</strong></h4></b-col>
                  <b-col cols="3"><h4><strong>Default Value</strong></h4></b-col>
                  <b-col cols="12" class="border mb-1"></b-col>
                </b-row>
                <b-row
                  v-for="(obj, index) in csvMappingFields"
                  :key="index"
                  class="my-1"
                >
                  
                  <b-col cols="3">
                    <strong>
                      {{ obj.csvField }}
                    </strong>             
                  </b-col>
                  <b-col cols="2">
                    <div>
                      {{ obj.csvFieldValue }}
                    </div>
                  </b-col>
                  <b-col cols="3">
                    <div>
                      {{ obj.vtigerField.label }}
                    </div>
                  </b-col>
                  <b-col cols="1">
                    <div v-if="obj.isPrimaryKey">
                      <b-form-checkbox
                        v-model="obj.isPrimaryKey"
                        :value="true"
                        :unchecked-value="false"
                        disabled
                      />
                    </div>
                  </b-col>
                  <b-col cols="3">
                    <div>
                      {{ obj.vtigerDefaultValue }}
                    </div>
                  </b-col>
                  <b-col cols="12" class="border my-50"></b-col>
                </b-row>
          </validation-observer>
        </tab-content>
        <template slot="footer" slot-scope="props">
          <div
            v-if="props.activeTabIndex !== 0"
            class="wizard-footer-left">
            <b-button variant="outline-secondary" @click="props.prevTab()">Indietro</b-button>
          </div>
          <div class="wizard-footer-right">
            <b-button v-if="props.activeTabIndex === 1" @click="saveMapping" variant="secondary" class="mr-2">Salva Mapping</b-button>
            <b-button @click="props.nextTab()" class="wizard-footer-right" :style="'background-color: #7367f0!important;'">{{ props.activeTabIndex === 2 ? 'Importa' : 'Successivo' }}</b-button>
          </div>
        </template>
      </form-wizard>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  BButton, BModal, VBModal, BFormFile, BRow, BCol, BImg, BFormGroup, BFormInput, BFormInvalidFeedback, BOverlay, BBadge, BAlert, BFormCheckbox, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import { getInfoModule, importCSVtoVtiger, getTypeModule, getMappingSaved, setTemplateMapping, deleteTemplateMap } from '@/@core/api-service/integration/vtiger'

Vue.use(ToastPlugin);
export default {
  components: {
    BButton,
    BModal,
    BFormFile,
    BRow,
    BCol,
    BImg,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BFormGroup,
    BFormInput,
    BBadge,
    BAlert,
    vSelect,
    BFormCheckbox,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      file: [],
      content: [],
      preview: {},
      csvMappingFields: [],
      mandatoryFields: [],
      csvMappato: [],
      parsed: false,
      apiLoading: false,
      vtigerFields: [],
      primaryKeyObj: {},
      typeModule: '',
      typeModuleOptions: [],
      mappingTemplateSaved: [],
    }
  },
  methods: {
    async getModules() {
      const response = await getTypeModule()
      this.typeModuleOptions = response.data.types
      console.log(this.typeModuleOptions)
    },
    onLoadFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.$papa.parse(files[0], {
        header: true,
        complete: this.onComplete,
        // error: undefined,
        skipEmptyLines: true,
        delimitersToGuess: [
          ",",
          "\t",
          "|",
          ";",
          this.$papa.RECORD_SEP,
          this.$papa.UNIT_SEP,
        ],
      });
    },
    onComplete(results, file) {
      console.log(results);
      this.content = [...results.data]
      this.preview = { ...this.content[0] }
      for (var key of Object.keys(this.preview)) {
          // console.log(key + " -> " + this.preview[key])
          const obj = {
            csvField: key,
            csvFieldValue: this.preview[key],
            vtigerField: { field: '', label: '' },
            vtigerDefaultValue: '',
            // isPrimaryKey: false,
            type: { name: ''},
          }
          this.csvMappingFields.push(obj)
      }
    },
    async formSubmitted() {
      console.log(this.csvMappato)
      const response = await importCSVtoVtiger(JSON.stringify({csv: this.csvMappato, primaryKey: this.primaryKeyObj, module: this.typeModule }))
      console.log('responseee', response)
      this.$refs.toastRef.show({
        title: 'Ops...!', content: `File mappato`, cssClass: 'e-toast-success', icon: 'e-success toast-icons',
      })
    },
    validationLoadCSV() {
      return new Promise((resolve, reject) => {
        this.$refs.loadCSVform.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            this.$refs.toastRef.show({
              title: 'Ops...!', content: `Devi inserire un file CSV e/o Selezionare un tipo di contatto`, cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
            })
            reject()
          }
        })
      })
    },
    async getInfoModuleVtiger(indexStep) {
      if(indexStep === 0) {
        this.apiLoading = true
        // console.log('typeModule', this.typeModule)
        const response = await getInfoModule(JSON.stringify({ module: this.typeModule}))
        this.apiLoading = false
        // console.log('responsessss', response)
        this.vtigerFields = response.data.description
        this.vtigerFields.unshift({ name: ' ', type: ' ', label: ' '})
        this.mandatoryFields = this.vtigerFields.filter(el => el.mandatory === true && (el.name !== 'assigned_user_id' && el.name !== 'contacttype'))

      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.loadCSVform.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationBeforeSubmit() {
      return new Promise((resolve, reject) => {
        this.$refs.submitForm.validate().then(success => {
          if (success) {

            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationMapping() {
      return new Promise((resolve, reject) => {
        this.$refs.mappingRules.validate().then(success => {
          const mandatoryField = this.mandatoryFields.map(el => el.name).filter(el => el !== 'assigned_user_id' && el.name !== 'contacttype')
          const csvMandatoryField = this.csvMappingFields.map(el => el.vtigerField.field).filter(el => el !== 'assigned_user_id' && el.name !== 'contacttype')
          console.log(mandatoryField)
          console.log(csvMandatoryField)
          const containsAll = mandatoryField.every(r=> csvMandatoryField.includes(r))
          const somePrimaryKey = this.csvMappingFields.some(el => el.isPrimaryKey)
          this.primaryKeyObj = this.csvMappingFields.find(el => el.isPrimaryKey)
          if (success && containsAll && somePrimaryKey) {
            this.mapCSVtoSend()
            resolve(true)
          } else {
            this.$refs.toastRef.show({
              title: 'Ops...!', content: `Non hai mappato tutti i campi obbligatori `, cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
            })
            reject()
          }
        })
      })
    },
    setPrimaryKeyField(obj) {
      this.csvMappingFields.forEach((el) => {
        if(el.csvField !== obj.csvField) el.isPrimaryKey = false
      })
    },
    addTypeForDefaultValue(obj) {
      const vtigerField = this.vtigerFields.find(el => el.name === obj.vtigerField.field)
      obj.type = vtigerField.type
      obj.vtigerDefaultValue = vtigerField.default
    },
    mapCSVtoSend() {
      const fieldMapped = this.csvMappingFields.filter(elementMapping => elementMapping.vtigerField.field !== '')
      this.csvMappato = this.content.map(el => {
        const obj = {
          fields: [],
        }
        fieldMapped.forEach(element => {
          obj.fields?.push({
            vtigerField: element.vtigerField.field,
            vtigerDefaultValue: element.vtigerDefaultValue,
            csvField: element.csvField,
            csvValue: el[String(element.csvField)].slice(0, 79),
            isPrimaryKey: element.isPrimaryKey,
          })
        })
        return obj
      })
      // console.log('csvMappato', csvMappato)
    },
    saveMapping() {
      console.log('salvaMapping')
      this.mapCSVtoSend()
      console.log('thiscsvMappato', this.csvMappato)
      const response = setTemplateMapping(JSON.stringify({ csv: this.csvMappingFields, module: this.typeModule })).then(res => console.log('a', res))
    },
    async loadMapping() {
      const response = await getMappingSaved()
      console.log('responseeeeeaaaa', response)
      let removeLastChar =  response.data.data.replace(/(\r\n|\n|\r)/gm, "")
      removeLastChar =  removeLastChar.slice(0, -1)
      removeLastChar = '[' + removeLastChar + ']'
      this.mappingTemplateSaved = JSON.parse(removeLastChar)
      console.log('mappingTemplateSaved', this.mappingTemplateSaved)
    },
    uploadTemplateMapped(template) {
      console.log('templateaaaaaaaaaaaaaa', template)
      this.csvMappingFields = template.mapping
      this.mapCSVtoSend()
      this.$refs.modalTemplateMapped.hide()
    },
     deleteTemplateMapped(template, index) {
      console.log(template, index)
      console.log('this.mappingTemplateSaved', this.mappingTemplateSaved)
      const templateToOverride = this.mappingTemplateSaved.filter((_, i) => i !== index)
      console.log('templateToOveride', templateToOverride)
      this.deletetemplate(JSON.stringify({ csv: templateToOverride, module: this.typeModule }))
      // const response = deleteTemplateMap(JSON.stringify({ csv: templateToOverride, module: this.typeModule })).then(res => console.log('res', res)).catch(e => e)
      // console.log('response', response)
    },
    async deletetemplate(data) {
      const response = await deleteTemplateMap(data)
      console.log('responseeeeeee', response)
      this.mappingTemplateSaved = JSON.parse(response.data.csv)
      this.$refs.modalTemplateMapped.hide()
    },
    resetModal() {
      this.file = []
      this.content = []
      this.preview = {}
      this.csvMappingFields= []
      this.mandatoryFields= []
      this.csvMappato= []
      this.parsed=false
      this.apiLoading= false
      this.vtigerFields= []
      this.primaryKeyObj= {}
      this.typeModule= ''
      this.typeModuleOptions= []
      this.mappingTemplateSaved= []
    },

  },
}
</script>
<style>

.control-section {
    height: 100%;
    min-height: 240px;
}
label.custom-file-label {
   background: #f8f8f8 !important;

}
.drop-zone {
  background: #f8f8f8 !important;
  border: 2px dashed #7367f0;
  min-height: 250px;
  width:100%;
  content: 'ciao'
}

.b-form-file.b-custom-control-lg .custom-file-label::after {
    height: 44px;
    visibility: hidden;
}
.myVisibility{
  visibility: hidden;
}
.wizard-header {
  display: none;
}

.modal-body {
  padding: 0;
}
ul.wizard-nav.wizard-nav-pills{
  display: flex;
  justify-content: center;
}
</style>
