<script>
/* eslint-disable */
</script>
<template>
  <div class="m-1">
    <ejs-toast
        id="toast_type"
        ref="toastRef"
        :position="{ X: 'Right' }"
      />
    <div class="my-3">
      <h1 class="text-center">
        <strong>
          Sincronizzazione Ordini Beautyspacenails
        </strong>
      </h1>
      <h6 class="text-center">
        Aggiornamento e Aggiunta Contatti, Prodotti e Ordini da Opencart verso Vtiger
      </h6>
    </div>
    <div class="border"></div>

<b-row class="my-5">
      <b-col class="text-center"><h1>Importa Contattiiii by email</h1>
      <b-button @click="syncConttattiEmail" variant="primary">Sync</b-button></b-col>
    </b-row>

    <b-row class="my-5">
      <b-col class="text-center"><h1>Importa Contattiiii</h1>
      <b-button @click="syncConttatti2" variant="primary">Sync</b-button></b-col>
    </b-row>



    <b-row>
      <b-col
        md="10"
        class="m-auto"
      >
        <ImportCSV />
      </b-col>
    </b-row>
    <b-row class="my-2">
      <b-col
        md="10"
        class="m-auto"
      >
        <h6 class="mb-2">
          Quest'operazione richiederà diversi minuti
        </h6>
        <div class="d-flex flex-column ">
          <b-form-group
            :label="'Sincronizza ordini data odierna'"
            label-class="font-weight-bolder"
            style="max-width: 300px;"
          >
            <flat-pickr
              v-model="dateToday"
              disabled
              placeholder="seleziona data di sincronizzazione"
              class="form-control mr-5"
              style="max-width: 300px"
            />
          </b-form-group>
          <div class="m-0 p-0">
            <b-button
              variant="primary"
              class="mr-2"
              :disabled="apiServerCalled"
              :config="configFlatPicker"
              @click="syncOrders(dateToday, true)"
            >
              Sincronizza Ordini Oggi
            </b-button>
            <b-spinner
              v-if="isLoadingSyncOrdersToday"
              :variant="'primary'"
              class="m-auto"
              style="vertical-align: middle;"
            />
          </div>
        </div>
        <h5
          v-if="responseServer !== ''"
          class="text-success mt-2 mt-md-0"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-75"
          />
          {{ responseServer }}
        </h5>
      </b-col>
    </b-row>
    <div class="border"></div>
    <b-row class="my-2">
      <b-col
        md="10"
        class="m-auto"
      >
        <h6 class="mb-2">
          Sincronizza ordini a partire
        </h6>
        <div class="d-flex flex-column ">
          <b-form-group
            :label="'Sincronizza ordini a partire da:'"
            label-class="font-weight-bolder"
            style="max-width: 300px;"
          >
            <flat-pickr
              v-model="dateFrom"
              placeholder="data di inizio"
              class="form-control mr-5"
              style="max-width: 300px"
              :config="configFlatPicker"
            />
          </b-form-group>
          <b-form-group
            :label="'Sincronizza ordini fino a giorno:'"
            label-class="font-weight-bolder"
            style="max-width: 300px;"
          >
            <flat-pickr
              v-model="dateTo"
              placeholder="data fine"
              class="form-control mr-5"
              style="max-width: 300px"
              :config="configFlatPicker"
            />
          </b-form-group>
          <div class="m-0 p-0">
            <b-button
              variant="primary"
              class="mr-2"
              :disabled="apiServerCalled || dateFromToInvalid"
              @click="syncOrders(dateFrom, false, dateTo)"
            >
              Sincronizza Ordini
            </b-button>
            <b-spinner
              v-if="isLoadingSyncOrdersByDate"
              :variant="'primary'"
              class="m-auto"
              style="vertical-align: middle;"
            />
          </div>
        </div>
        <p v-if="dateFromToInvalid" class="text-danger mt-2 mt-md-0">
          <feather-icon
            icon="XIcon"
            class="mr-75 text-danger"
          />
          La data di fine deve essere successiva alla data di inizio
        </p>
        <h5
          v-if="responseServerDateFromTo.messaggio !== ''"
          class="text-success mt-2 mt-md-0"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-75"
          />
          {{ responseServerDateFromTo.messaggio }}
        </h5>
      </b-col>
    </b-row>
    <div class="border"></div>
    <b-row class="my-2">
      <b-col
        md="10"
        class="m-auto"
      >
        <h6 class="mb-2">
          Inserisci ID ordine Opencart da sincronizzare
        </h6>
        <div>
          <validation-observer ref="sync-opencart-order-by-id">
            <validation-provider
              #default="{ errors }"
              name="numero ordine"
              rules="required"
              class="d-flex flex-column "
            >
              <b-form-group
                :label="'Inserisci nr.Ordine Opencart'"
                label-class="font-weight-bolder"
                class="mr-2"
                 style="max-width: 300px;"
              >
                <b-form-input
                  id="opencartOrderNumber"
                  v-model="orderNumberOpencart"
                  type="number"
                  placeholder="Inserisci numero ordine Opencart"
                  class=""
                  style="width:270px"
                />
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </b-form-group>
              <div class="m-0 p-0">
                <b-button
                  variant="primary"
                  class="mr-2"
                  :disabled="apiServerCalled || errors.length > 0"
                  @click="syncOrderById(orderNumberOpencart)"
                >
                  Sincronizza Ordine
                </b-button>
                <b-spinner
                  v-if="isLoadingSyncOrderId"
                  :variant="'primary'"
                  class="m-auto"
                  style="vertical-align: middle;"
                />
              </div>
            </validation-provider>
          </validation-observer>
        </div>
        <h5
          v-if="responseServerSyncOrderById.esito === 'KO'"
          class="text-danger mt-2 mt-md-0"
        >
          <feather-icon
            icon="XIcon"
            class="mr-75 text-danger"
          />
          {{ responseServerSyncOrderById.messaggio }}
        </h5>
        <h5
          v-else-if="responseServerSyncOrderById.esito === 'OK'"
          class="text-success mt-2 mt-md-0"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-75 text-danger"
          />
          {{ responseServerSyncOrderById.messaggio }}
        </h5>
      </b-col>
    </b-row>
    <div class="border"></div>
    <b-row class="my-2">
      <b-col
        md="10"
        class="m-auto"
      >
        <h6 class="mb-2">
          Inserisci ID Cliente Opencart da sincronizzare
        </h6>
        <div>
          <validation-observer ref="sync-customer-by-id">
            <validation-provider
              #default="{ errors }"
              name="id cliente"
              rules="required"
              class="d-flex flex-column "
            >
              <b-form-group
                :label="'Inserisci cod. cliente Opencart'"
                label-class="font-weight-bolder"
                class="mr-2"
                style="max-width: 300px;"
              >
                <b-form-input
                  id="opencartIdCustomer"
                  v-model="idCustomerOpencart"
                  type="number"
                  placeholder="Inserisci id cliente opencart"
                  class=""
                  style="width:270px"
                />
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </b-form-group>
              <div class="m-0 p-0">
                <b-button
                  variant="primary"
                  class="mr-2"
                  :disabled="apiServerCalled || errors.length > 0"
                  @click="syncCustomerById(idCustomerOpencart)"
                >
                  Sincronizza Cliente
                </b-button>
                <b-spinner
                  v-if="isLoadingSyncCustomerById"
                  :variant="'primary'"
                  class="m-auto"
                  style="vertical-align: middle;"
                />
              </div>
            </validation-provider>
          </validation-observer>
        </div>
        <h5
          v-if="responseServerSyncCustomerById.esito === 'KO'"
          class="text-danger mt-2 mt-md-0"
        >
          <div>
            <feather-icon
              icon="XIcon"
              class="mr-75 text-danger"
            />
            {{ responseServerSyncCustomerById.messaggio }}
          </div>
        </h5>
        <h5
          v-else-if="responseServerSyncCustomerById.esito === 'OK'"
          class="text-success mt-2 mt-md-0"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-75"
          />
          {{ responseServerSyncCustomerById.messaggio }}
        </h5>
      </b-col>
    </b-row>
    <div class="border"></div>
    <b-row class="my-2">
      <b-col
        md="10"
        class="m-auto"
      >
        <h6>Controlla tutti gli ordini che hanno fallito la sincronizzazione</h6>
        <b-button
          v-b-modal.modal-xl
          :disabled="apiServerCalled"
          @click="getObjectNotSync"
        >
          apri
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      id="modal-xl"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      title="Ordini e Utenti"
    >
      <div
        v-for="(obj, index) in objectsNotSynched"
        :key="index"
        class="my-1"
      >
        <b-row
          v-if="obj.typeModule === 'SalesOrder' && obj.syncronized === false"
          class="d-flex my-2"
        >
          <b-col>Tipo: <b>Ordine</b></b-col>
          <b-col>Nr. Ordine Opencart: <b>{{ obj.id.subject }}</b></b-col>
          <b-col>Data Ordine: <b>{{ obj.id.cf_salesorder_dataorder }}</b></b-col>
          <b-col class="d-flex justify-content-end">
            <b-button v-if="objectsNotSynched.length > 0"
              type="button"
              class="d-flex"
              style="vertical-align: middle;"
              @click="syncOrder(obj.id.subject, index)" :disabled="obj.loadingSync">
              Sinc. Ordine
              <b-spinner
                  v-if="obj.elementLoading"
                  :variant="'primary'"
                  class="m-auto"
                  style="vertical-align: middle;"
                />
            </b-button>
          </b-col>
        </b-row>
        <b-row
          v-else-if="obj.typeModule === 'Contacts' && obj.syncronized === false"
          class="d-flex my-2"
        >
          <b-col>Tipo: <b>Contatto</b></b-col>
          <b-col>Nr. Cliente Opencart: <b>{{ obj.id.cf_contacts_customerecommerceid }}</b></b-col>
          <b-col></b-col>
          <b-col class="d-flex justify-content-end">
            <b-button
              v-if="objectsNotSynched.length > 0"
              @click="syncCustomer(obj.id.cf_contacts_customerecommerceid, index)" class="align-self-end d-flex" :disabled="obj.loadingSync">
                Sincr. Contatto
                <b-spinner
                  v-if="obj.elementLoading"
                  :variant="'primary'"
                  class="m-auto"
                  style="vertical-align: middle;"
                />
              </b-button>
            </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BSpinner, BModal, VBModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import axios from 'axios'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Italian } from 'flatpickr/dist/l10n/it'
import Ripple from 'vue-ripple-directive'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import ImportCSV from '../../components/modal/ImportCSV.vue'

Vue.use(ToastPlugin)

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BSpinner,
    flatPickr,
    BModal,
    ImportCSV,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  watch: {
    dateTo() {
      // console.log('this.dateTo', this.dateTo)
      if (moment(this.dateTo).isSameOrBefore(moment(this.dateFrom))) {
        // console.log(moment(this.dateTo).isBefore(moment(this.dateFrom)))
        // console.log(this.dateTo)
        this.dateFromToInvalid = true
      } else {
        this.dateFromToInvalid = false
      }
    },
  },
  data() {
    return {
      required,
      objectsNotSynched: [],
      today: new Date(),
      locale: 'it',
      dateDefault: null,
      dateToday: moment(new Date()).format('YYYY-MM-DD'),
      // dateFrom: new Date().toISOString().slice(0, 10),
      dateFrom: moment(new Date()).format('YYYY-MM-DD'),
      // dateTo: new Date().toISOString().slice(0, 10),
      dateTo: moment(new Date()).add(1, 'd').format('YYYY-MM-DD'),
      dateFromToInvalid: false,
      isLoadingSyncOrderId: false,
      isLoadingSyncOrdersByDate: false,
      isLoadingSyncOrdersToday: false,
      isLoadingSyncCustomerById: false,
      apiServerCalled: false,
      responseServer: '',
      responseServerSyncOrderById: {
        esito: '',
        messaggio: '',
      },
      responseServerSyncCustomerById: {
        esito: '',
        messaggio: '',
      },
      responseServerDateFromTo: {
        esito: '',
        messaggio: '',
      },
      orderNumberOpencart: '',
      idCustomerOpencart: '',
      configFlatPicker: {
        locale: Italian, // locale for this instance only
      },
    }
  },
  mounted() {
    localize(this.locale)
  },
  methods: {
    syncOrderById(orderNumberOpencart) {
      let orderOpencart = []
      this.$refs['sync-opencart-order-by-id'].validate().then(async success => {
        if (success) {
          this.apiServerCalled = true
          this.responseServerSyncOrderById.esito = ''
          this.responseServerSyncOrderById.messaggio = ''
          this.isLoadingSyncOrderId = true
          orderOpencart = await this.callServerSyncOrdersByIdOrderOpencart(orderNumberOpencart)
          // console.log('response', orderOpencart)
          this.apiServerCalled = false
          this.isLoadingSyncOrderId = false
          if (orderOpencart.data.esito === 'KO') {
            this.responseServerSyncOrderById.esito = 'KO'
            this.responseServerSyncOrderById.messaggio = orderOpencart.data.messaggio
          } else if (orderOpencart.data.esito === 'OK') {
            this.responseServerSyncOrderById.esito = 'OK'
            this.responseServerSyncOrderById.messaggio = orderOpencart.data.messaggio
          }
          // return orderOpencart
        }
        return orderOpencart
      })
      return orderOpencart
    },
    syncOrders(dateFrom, isToday, dateTo) {
      // this.apiServerCalled = true
      let dateToSend = dateTo
      if (dateToSend === undefined) {
        dateToSend = moment(dateFrom).add(1, 'd').format('YYYY-MM-DD')
      }
      if (isToday) {
        this.isLoadingSyncOrdersToday = true
      } else {
        this.isLoadingSyncOrdersByDate = true
      }
      this.isLoadingSyncOrders = true
      this.apiServerCalled = true
      this.responseServer = ''
      this.callServerSyncOrders(dateFrom, dateToSend)
        .then(res => {
          // console.log('res', res)
          this.isLoadingSyncOrdersByDate = false
          this.isLoadingSyncOrdersToday = false
          this.apiServerCalled = false
          if (res.data.esito === 'OK' && isToday) {
            this.responseServer = res.data.messaggio
          } else if (res.data.esito === 'OK' && !isToday) {
            this.responseServerDateFromTo.messaggio = res.data.messaggio
            this.responseServerDateFromTo.esito = 'OK'
          }
        })
        .catch(e => {
          // console.log(e)
          this.isLoadingSyncOrdersByDate = false
          this.isLoadingSyncOrdersToday = false
          this.apiServerCalled = false
          this.responseServer = e
        })
      // this.apiServerCalled = false
    },
    syncCustomerById(idCustomerOpencart) {
      this.apiServerCalled = true
      this.$refs['sync-customer-by-id'].validate().then(async success => {
        if (success) {
          this.responseServerSyncCustomerById.messaggio = ''
          this.responseServerSyncCustomerById.esito = ''
          this.isLoadingSyncCustomerById = true
          const customerOpencart = await this.syncCustomerFromOpencartToVtiger(idCustomerOpencart)
          this.apiServerCalled = false
          // console.log(customerOpencart)
          this.isLoadingSyncCustomerById = false
          if (customerOpencart.data.esito === 'OK') {
            this.responseServerSyncCustomerById.esito = 'OK'
            this.responseServerSyncCustomerById.messaggio = customerOpencart.data.messaggio
          } else if (customerOpencart.data.esito === 'KO') {
            this.responseServerSyncCustomerById.esito = 'KO'
            this.responseServerSyncCustomerById.messaggio = 'Errore Sincronizzazione Contatto'
          }
        }
        // this.apiServerCalled = false
      })
    },
    callServerSyncOrders(dateFrom, dateTo) {
      this.responseServer = ''
      this.responseServerDateFromTo.messaggio = ''
      return new Promise((resolve, reject) => {
        axios.post('https://managersync.beautyspacenails.com/api/syncOrders', { dateFrom, dateTo },
          {
            headers: {
              classe: 'Sinc-Sync',
              metodo: 'SincOrders',
              ACL: '',
              // Authorization: 'Basic YmVhdXR5NzY6QmVhdXR5NzYkJA==',
            },
          }).then(res => resolve(res))
          .catch(error => reject(error))
      })
    },
    callServerSyncOrdersByIdOrderOpencart(idOrderOpencart) {
      this.responseServerSyncOrderById.messaggio = ''
      return new Promise((resolve, reject) => {
        axios.post('https://managersync.beautyspacenails.com/api/syncOrderById', { idOrderOpencart },
          {
            headers: {
              classe: 'Sinc-Sync',
              metodo: 'syncOrderById',
              ACL: '',
              // Authorization: 'Basic YmVhdXR5NzY6QmVhdXR5NzYkJA==',
            },
          }).then(res => resolve(res))
          .catch(error => reject(error))
      })
    },
    syncCustomerFromOpencartToVtiger(idCustomerOpencart) {
      this.responseServerSyncCustomerById.messaggio = ''
      return new Promise((resolve, reject) => {
        axios.post('https://managersync.beautyspacenails.com/api/syncCustomerToVtiger', { idCustomerOpencart },
          {
            headers: {
              classe: 'Sinc-Sync',
              metodo: 'syncCustomerToVtiger',
              ACL: '',
              // Authorization: 'Basic YmVhdXR5NzY6QmVhdXR5NzYkJA==',
            },
          }).then(res => resolve(res))
          .catch(error => reject(error))
      })
    },
    async getObjectNotSync() {
      // await axios.post('http://localhost:8000/api/getNotSyncObj', {},
      await axios.post('https://managersync.beautyspacenails.com/api/getNotSyncObj', {},
        {
          headers: {
            classe: 'Sinc-Sync',
            metodo: 'getNotSyncObj',
            ACL: '',
          },
        }).then(res => {
        if (res.data.esito === 'OK') {
          this.objectsNotSynched = res.data.data
          this.objectsNotSynched = this.objectsNotSynched.map(el => ({ ...el, loadingSync: false, syncronized: false }))
        }
      }).catch(error => error)
    },
    async setObjectNotSync(newObjectString) {
      return new Promise((resolve, reject) => {
        axios.post('https://managersync.beautyspacenails.com/api/setObjectNotSync', { newObjectString },
          {
            headers: {
              classe: 'Sinc-Sync',
              metodo: 'setObjectNotSync',
              ACL: '',
              // Authorization: 'Basic YmVhdXR5NzY6QmVhdXR5NzYkJA==',
            },
          }).then(res => resolve(res))
          .catch(error => reject(error))
      })
    },
    async syncOrder(id, index1) {
      this.objectsNotSynched = this.objectsNotSynched.map(el => ({ ...el, loadingSync: true, elementLoading: false }))
      this.objectsNotSynched[index1].elementLoading = true
      const orderSynchronized = await this.callServerSyncOrdersByIdOrderOpencart(id)
      if (orderSynchronized.data.esito === 'OK') {
        // sync ok
        this.$refs.toastRef.show({
          title: 'Sincronizzazione', content: 'L\'ordine è stato sincronizzato ', cssClass: 'e-toast-success', icon: 'e-success toast-icons',
        })
        const index = this.objectsNotSynched.findIndex(element => element.typeModule === 'SalesOrder' && String(element.id.subject) === String(id) && element.syncronized === false)
        if (index !== -1) {
          this.objectsNotSynched[index1].elementLoading = false
          this.objectsNotSynched[index].syncronized = true
          this.objectsNotSynched = this.objectsNotSynched.map(el => ({ ...el, loadingSync: false }))
        }
        const newArrayToString = this.prepareObjectsStrings()
        await this.setObjectNotSync(newArrayToString)
        /* if (newLogString.data.esito === 'OK') {
          this.objectsNotSynched = this.objectsNotSynched.map(el => ({ ...el, loadingSync: false }))
        } else if (newLogString.data.esito === 'KO') {
          this.objectsNotSynched = this.objectsNotSynched.map(el => ({ ...el, loadingSync: false }))
        } */
      } else if (orderSynchronized.data.esito === 'KO') {
        // sync in errore
        this.objectsNotSynched[index1].elementLoading = false
        this.$refs.toastRef.show({
          title: 'Errore Sincronizzazione', content: 'L\'ordine non può essere sincronizzato ', cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
        })
        const index = this.objectsNotSynched.findIndex(element => element.typeModule === 'SalesOrder' && String(element.id.subject) === String(id) && element.syncronized === false)
        if (index !== -1) {
          this.objectsNotSynched[index1].elementLoading = false
          this.objectsNotSynched[index].syncronized = true
          this.objectsNotSynched = this.objectsNotSynched.map(el => ({ ...el, loadingSync: false }))
        }
        const newArrayToString = this.prepareObjectsStrings()
        await this.setObjectNotSync(newArrayToString)
        this.objectsNotSynched = this.objectsNotSynched.map(el => ({ ...el, loadingSync: false }))
      } else {
        this.objectsNotSynched = this.objectsNotSynched.map(el => ({ ...el, loadingSync: false }))
      }
    },
    async syncCustomer(id, index1) {
      this.objectsNotSynched = this.objectsNotSynched.map(el => ({ ...el, loadingSync: true }))
      this.objectsNotSynched[index1].elementLoading = true
      const customerSyncronized = await this.syncCustomerFromOpencartToVtiger(id)
      // console.log('customerSyncronized', customerSyncronized)
      if (customerSyncronized.data.esito === 'OK') {
        // sync ok
        this.objectsNotSynched[index1].elementLoading = false
        this.$refs.toastRef.show({
          title: 'Sincronizzato!', content: 'Cliente sincronizzato correttamente', cssClass: 'e-toast-success', icon: 'e-success toast-icons',
        })
        const index = this.objectsNotSynched.findIndex(element => element.typeModule === 'Contacts' && String(element.id.cf_contacts_customerecommerceid) === String(id) && element.syncronized === false)
        if (index !== -1) {
          this.objectsNotSynched[index].syncronized = true
          this.objectsNotSynched = this.objectsNotSynched.map(el => ({ ...el, loadingSync: false }))
        }
        const newArrayToString = this.prepareObjectsStrings()
        await this.setObjectNotSync(newArrayToString)
        // console.log('newLogs', newLogs)
      } else if (customerSyncronized.data.esito === 'KO') {
        // sync in errore
        this.objectsNotSynched[index1].elementLoading = false
        this.$refs.toastRef.show({
          title: 'Errore Sincronizzazione', content: 'Il cliente non può essere sincronizzato ', cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
        })
        const index = this.objectsNotSynched.findIndex(element => element.typeModule === 'Contacts' && String(element.id.cf_contacts_customerecommerceid) === String(id) && element.syncronized === false)
        if (index !== -1) {
          this.objectsNotSynched[index].syncronized = true
          this.objectsNotSynched = this.objectsNotSynched.map(el => ({ ...el, loadingSync: false }))
        }
        const newArrayToString = this.prepareObjectsStrings()
        await this.setObjectNotSync(newArrayToString)
        // console.log('newLogs', newLogs)
        this.objectsNotSynched = this.objectsNotSynched.map(el => ({ ...el, loadingSync: false }))
      } else {
        this.objectsNotSynched = this.objectsNotSynched.map(el => ({ ...el, loadingSync: false }))
      }
    },
    prepareObjectsStrings() {
      const prova = [...this.objectsNotSynched]
      const prepareArray = prova.filter(el => el.syncronized === false)
      return JSON.stringify(prepareArray).slice(1, -1).concat(',')
    },
    syncConttatti2API(){
      return new Promise((resolve, reject) => {
        axios.post('https://managersync.beautyspacenails.com/api/syncCustomerToVtiger2', {},
          {
            headers: {
              classe: 'Sinc-Sync',
              metodo: 'syncCustomerToVtiger2',
              ACL: '',
              // Authorization: 'Basic YmVhdXR5NzY6QmVhdXR5NzYkJA==',
            },
          }).then(res => resolve(res))
          .catch(error => reject(error))
      })
    },
    async syncConttatti2() {
      const prova = await this.syncConttatti2API();
      console.log(prova)
    },
    async syncConttattiEmail() {
      const prova = await this.syncConttatti2APIEmail();
      console.log(prova)
    },
    syncConttatti2APIEmail(){
      console.log('qui')
      return new Promise((resolve, reject) => {
        axios.post('https://managersync.beautyspacenails.com/api/syncCustomerToVtigerByEmail', {},
          {
            headers: {
              classe: 'Sinc-Sync',
              metodo: 'syncCustomerToVtigerByEmail',
              ACL: '',
              // Authorization: 'Basic YmVhdXR5NzY6QmVhdXR5NzYkJA==',
            },
          }).then(res => resolve(res))
          .catch(error => reject(error))
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
