/* eslint-disable  */
import axios from 'axios'

export function getInfoModule(data) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    // axios.post(process.env.VUE_APP_URL_API_SERVER, {},
    axios.post('http://localhost:8000/api/getModuleInfo', data,
        {
          headers: {
          },
        }).then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function importCSVtoVtiger(data) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    // axios.post(process.env.VUE_APP_URL_API_SERVER, {},
    axios.post('http://localhost:8000/api/importCSVtoVtiger', data,
          {
            headers: {
          },
        }).then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function getTypeModule() {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    // axios.post(process.env.VUE_APP_URL_API_SERVER, {},
    axios.post('http://localhost:8000/api/getTypeModule', {},
          {
            headers: {
          },
        }).then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function getMappingSaved() {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    // axios.post(process.env.VUE_APP_URL_API_SERVER, {},
    axios.post('http://localhost:8000/api/getMappingSaved', {},
          {
            headers: {
          },
        }).then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function setTemplateMapping(data) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    // axios.post(process.env.VUE_APP_URL_API_SERVER, {},
    axios.post('http://localhost:8000/api/setTemplateMapping', data,
          {
            headers: {
          },
        }).then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function deleteTemplateMap(data) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    // axios.post(process.env.VUE_APP_URL_API_SERVER, {},
    axios.post('http://localhost:8000/api/deleteTemplate', data,
          {
            headers: {
          },
        }).then(res => resolve(res))
      .catch(error => reject(error))
  })
}